import { Controller } from "@hotwired/stimulus"
import { DateTime } from "luxon"

export default class extends Controller {
  static targets = [
    'contacts',
    'table'
  ]

  static values = {
    url: String,
    tabulatorTable: Object,
    metadataKeys: Array,
    filtersOpen: Boolean,
  }

  connect() {
    this.tabulatorTable = new Tabulator(this.tableTarget, {
      layout: "fitDataStretch",
      placeholder: "No contacts found",
      // pagination: true,
      // paginationSize: 100,
      // paginationSizeSelector: true,
      height: `calc(100vh - ${this.filtersOpenValue ? "131px" : "5rem"})`,
      renderHorizontal: "virtual",
      dataSendParams: {
        "page": "start",
        "size": "length"
      },
      ajaxURL: this.urlValue,
      columns: 
      [
        { title: "Reference", field: "reference", formatter: "link", frozen: false,
          formatterParams: {
          url: (cell) => {
            return `/contacts/${cell.getRow().getData().reference}`
          },
        }},
        { title: "Display Name", field: "display_name", formatter: "link", formatterParams: {
          url: (cell) => {
            return `/contacts/${cell.getRow().getData().reference}`
          },
        }},
        { 
          title: "Status", 
          field: "contact_status", 
          formatter: function(cell) {
            if (!cell.getValue()) {
              return "";
            }
            const currentUrl = new URL(window.location.href);
            currentUrl.searchParams.set('contact_status', cell.getValue());
            return `<a href="${currentUrl.toString()}" class="link">${cell.getValue()}</a>`;
          }
        },
        { 
          title: "Type", 
          field: "contact_type", 
          formatter: function(cell) {
            const currentUrl = new URL(window.location.href);
            currentUrl.searchParams.set('contact_type', cell.getValue());
            return `<a href="${currentUrl.toString()}" class="link">${cell.getValue()}</a>`;
          }
        },
        { 
          title: "Classification", 
          field: "contact_classification", 
          formatter: function(cell) {
            if (!cell.getValue()) {
              return "";
            }
            const currentUrl = new URL(window.location.href);
            currentUrl.searchParams.set('contact_classification', cell.getValue());
            return `<a href="${currentUrl.toString()}" class="link">${cell.getValue()}</a>`;
          }
        },
        { 
          title: "External ID", 
          field: "external_id", 
          formatter: function(cell) {
            if (!cell.getValue()) {
              return "";
            }
            const currentUrl = new URL(window.location.href);
            currentUrl.searchParams.set('external_id', cell.getValue());
            return `<a href="${currentUrl.toString()}" class="link">${cell.getValue()}</a>`;
          }
        },
        { title: "Created", field: "created_at", formatter: "datetime", formatterParams: {
          inputFormat: "iso",
          outputFormat: "MMM d, yy 'at' t",
          invalidPlaceholder: "(invalid date)",
          timezone: "America/Los_Angeles",
        }},
        { title: "Updated", field: "updated_at", formatter: "datetime", formatterParams: {
          inputFormat: "iso",
          outputFormat: "MMM d, yy 'at' t",
          invalidPlaceholder: "(invalid date)",
          timezone: "America/Los_Angeles",
        }},
      ],
      dependencies: {
        DateTime: DateTime,
      },  
    })

    // #download_csv
    document.getElementById("download_csv").addEventListener("click", () => {
      this.tabulatorTable.download("csv", `contacts-${DateTime.now().toFormat('yyyy-MM-dd-HH-mm')}.csv`)
    })

    this.tabulatorTable.on("rowDblClick", function(e, row){
      window.location.href = `/contacts/${row.getData().reference}`
    });
  }
}
