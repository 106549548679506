import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    isSelected: { type: Boolean, default: false }
  }

  connect() {
    if (this.isSelectedValue) {
      this.element.classList.add('icon-selected')
    }
    this.element.addEventListener("click", this.toggleIconSelected.bind(this))
  }

  disconnect() {
    this.element.removeEventListener("click", this.toggleIconSelected.bind(this))
  }

  toggleIconSelected() {
    // clear selected class on all data-controller=icon-select
    document.querySelectorAll('[data-controller="icon-selector"].icon-selected').forEach((element) => {
      element.classList.remove('icon-selected')
    })
    this.isSelectedValue = !this.isSelectedValue
    this.element.classList.toggle('icon-selected')
    // if selected, set #selected_icon
    if (this.isSelectedValue) {
      document.getElementById('selected_icon').value = this.element.dataset.icon
    }
  }
}
