import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    paperworkAttachmentId: String,
  }

  next() {
    const currentLink = document.querySelector(`.paperwork-attachment-modal-link[data-paperwork-attachment-id="${this.paperworkAttachmentIdValue}"]`)
    const container = currentLink?.closest('.paperwork-attachment-modal-link-container')
    const nextLink = container?.nextElementSibling?.querySelector(`.paperwork-attachment-modal-link`)
    
    if (nextLink) {
      nextLink.click()
    }
  }

  prev() {
    const currentLink = document.querySelector(`.paperwork-attachment-modal-link[data-paperwork-attachment-id="${this.paperworkAttachmentIdValue}"]`)
    const container = currentLink?.closest('.paperwork-attachment-modal-link-container')
    const prevLink = container?.previousElementSibling?.querySelector('.paperwork-attachment-modal-link')
    
    if (prevLink) {
      prevLink.click()
    }
  }
}
