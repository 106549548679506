import { Controller } from "@hotwired/stimulus"
import { DateTime } from "luxon"
import { createAttributeFilterFormatter } from "./tabulator_helpers"

export default class extends Controller {
  static targets = [
    'tasks',
    'table'
  ]

  static values = {
    url: String,
    tabulatorTable: Object,
    filtersOpen: Boolean,
  }

  connect() {
    this.tabulatorTable = new Tabulator(this.tableTarget, {
      layout: "fitDataStretch",
      placeholder: "No tasks found",
      height: `calc(100vh - ${this.filtersOpenValue ? "131px" : "5rem"})`,
      renderHorizontal: "virtual",
      dataSendParams: {
        "page": "start",
        "size": "length"
      },
      ajaxURL: this.urlValue,
      columns: 
      [
        { title: "Reference", field: "reference", formatter: "link", frozen: false,
          formatterParams: {
          url: (cell) => {
            return `/tasks/${cell.getRow().getData().reference}`
          },
        }},
        { title: "Title", field: "title", formatter: "link", formatterParams: {
          url: (cell) => {
            return `/tasks/${cell.getRow().getData().reference}`
          },
        }},
        { title: "State", field: "state", formatter: createAttributeFilterFormatter("state") },
        { title: "Task Type", field: "type_display_name", formatter: createAttributeFilterFormatter("task_type_name") },
        { title: "Paperwork Process", field: "paperwork_process.title", formatter: "link", formatterParams: {
          url: (cell) => {
            const data = cell.getRow().getData()
            return `/processes/${data.paperwork_process.reference}`
          },
        }},
        { title: "Bot", field: "bot.name", formatter: (cell) => {
            if (!cell.getValue()) {
              return "";
            }

            const data = cell.getRow().getData();

            const currentUrl = new URL(window.location.href);
            currentUrl.searchParams.set("bot", data.bot.key);
            return `<a href="${currentUrl.toString()}" class="link">${cell.getValue()}</a>`;
          },
        },
        { title: "Due", field: "due_at", formatter: "datetime", formatterParams: {
          inputFormat: "iso",
          outputFormat: "MMM d, yy 'at' t",
          invalidPlaceholder: "(invalid date)",
          timezone: "America/Los_Angeles",
        }},
        { title: "Created", field: "created_at", formatter: "datetime", formatterParams: {
          inputFormat: "iso",
          outputFormat: "MMM d, yy 'at' t",
          invalidPlaceholder: "(invalid date)",
          timezone: "America/Los_Angeles",
        }},
        { title: "Updated", field: "updated_at", formatter: "datetime", formatterParams: {
          inputFormat: "iso",
          outputFormat: "MMM d, yy 'at' t",
          invalidPlaceholder: "(invalid date)",
          timezone: "America/Los_Angeles",
        }},
      ],
      dependencies: {
        DateTime: DateTime,
      },  
    })

    // #download_csv
    document.getElementById("download_csv").addEventListener("click", () => {
      this.tabulatorTable.download("csv", `tasks-${DateTime.now().toFormat('yyyy-MM-dd-HH-mm')}.csv`)
    })

    this.tabulatorTable.on("rowDblClick", function(e, row){
      window.location.href = `/tasks/${row.getData().reference}`
    });
  }
}
