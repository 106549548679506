import { Controller } from "@hotwired/stimulus"
import { DateTime } from "luxon"

export default class extends Controller {
  static targets = [
    'processes',
    'table'
  ]

  static values = {
    url: String,
    botName: String,
    tabulatorTable: Object,
    metadataKeys: Array,
    filtersOpen: Boolean,
  }

  connect() {
    this.tabulatorTable = new Tabulator(this.tableTarget, {
      layout: "fitDataStretch",
      placeholder: "No paperwork found",
      // pagination: true,
      // paginationSize: 100,
      // paginationSizeSelector: true,
      height: `calc(100vh - ${this.filtersOpenValue ? "131px" : "5rem"})`,
      renderHorizontal: "virtual",
      dataSendParams: {
        "page": "start",
        "size": "length"
      },
      ajaxURL: this.urlValue,
      columns: 
      [
        { title: "ID", field: "id", visible: false },
        { title: "Reference", field: "reference", formatter: "link", frozen: false,
          formatterParams: {
          url: (cell) => {
            return `/processes/${cell.getRow().getData().reference}`
          },
        }},
        { 
          title: "State", 
          field: "state", 
          formatter: function(cell) {
            const currentUrl = new URL(window.location.href);
            currentUrl.searchParams.set('state', cell.getValue());
            return `<a href="${currentUrl.toString()}" class="link">${cell.getValue()}</a>`;
          }
        },
        { title: "Subject", field: "title", formatter: "link", formatterParams: {
          label: (cell) => {
            return cell.getRow().getData().title || "(no subject)"
          },
          url: (cell) => {
            return `/processes/${cell.getRow().getData().reference}`
          },
        }},
        { title: "Created", field: "created_at", formatter: "datetime", formatterParams: {
          inputFormat: "iso",
          outputFormat: "MMM d, yy 'at' t",
          invalidPlaceholder: "(invalid date)",
          timezone: "America/Los_Angeles",
        }},
        { title: "Updated", field: "updated_at", formatter: "datetime", formatterParams: {
          inputFormat: "iso",
          outputFormat: "MMM d, yy 'at' t",
          invalidPlaceholder: "(invalid date)",
          timezone: "America/Los_Angeles",
        }},
        {
          title: "Contacts",
          field: "contacts",
          sorter: "array",
          sorterParams: {
            type: "string",
            alignEmptyValues: "top",
            valueMap: "display_name",
          },
          formatter: function(cell) {
            const contacts = cell.getValue() || [];
            return contacts.map(contact => {
              const currentUrl = new URL(window.location.href);
              currentUrl.searchParams.set('contact', contact.reference);
              return `<a href="${currentUrl.toString()}" class="link">${contact.display_name}</a>`;
            }).join(", ");
          }
        },
        ...this.metadataKeysValue.map(key => ({ 
          title: key, 
          field: `extracted_data.${key}` 
        }))
      ],
      dependencies: {
        DateTime: DateTime,
      },  
    })

    // #download_csv
    document.getElementById("download_csv").addEventListener("click", () => {
      this.tabulatorTable.download("csv", `${this.botNameValue}-paperwork-${DateTime.now().toFormat('yyyy-MM-dd-HH-mm')}.csv`)
    })

    this.tabulatorTable.on("rowDblClick", function(e, row){
      window.location.href = `/processes/${row.getData().reference}`
    });
  }
}
