export const createAttributeFilterFormatter = (paramFilterName) => {
  return (cell) => {
    if (!cell.getValue()) {
      return "";
    }

    const data = cell.getRow().getData();

    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set(paramFilterName, [paramFilterName ? data[paramFilterName] : cell.getValue()]);
    return `<a href="${currentUrl.toString()}" class="link">${cell.getValue()}</a>`;
  }
}
