import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "input",
  ]

  connect() {
    this.inputTarget.addEventListener('input', this.sanitizeInput.bind(this));
  }

  disconnect() {
    this.inputTarget.removeEventListener('input', this.sanitizeInput.bind(this));
  }

  sanitizeInput(event) {
    const sanitized = event.target.value.replace(/[^a-zA-Z0-9_]/g, '');
    event.target.value = sanitized;
  }

  remove(event) {
    this.element.remove()
    event.preventDefault();
    return false;
  }
}
