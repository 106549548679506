// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails"
import "daisyui"
import { TabulatorFull as Tabulator } from 'tabulator-tables'
import "./controllers"

window.Tabulator = Tabulator;

window.scrollToBottom = () => {
  setTimeout(() => {
    window.scrollTo(0, document.body.scrollHeight); }, 50);
}

window.goBack = (event, link) => {
  event.preventDefault();

  // Check if there is a history to go back to
  if (window.history.length > 1) {
    window.history.back();
  } else {
    window.location.href = link.href; // Fallback to the link's href
  }
}

window.scrollFieldIntoView = (dom_id) => {
  setTimeout(() => {
    const eventDom = document.getElementById(dom_id);
    eventDom.querySelector("input[type='text'], input[type='email'], input[type='password'], input[type='number'], input[type='tel'], input[type='url'], select, textarea")?.focus();
    eventDom.scrollIntoView({ behavior: "smooth", block: "center" });
  }, 100);
}

