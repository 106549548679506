import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
  }

  connect() {
    console.log('new_custom_fields_controller')
  }

  disconnect() {
  }

  addNewField(event) {
    event.preventDefault()
    this.renderForm()
  }

  renderForm() {
    const url = this.data.get("url")
    fetch(url, {
      headers: {
        "Accept": "text/vnd.turbo-stream.html"
      }
    })
      .then(response => response.text())
      .then(html => {
        this.element.querySelector(".form-container").innerHTML += html
      })
  }
}
