import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
  }

  connect() {
  }

  disconnect() {
  }

  removeField(event) {
    event.preventDefault()
    this.element.remove()
  }
}
