import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
  }

  static targets = [
    "dataExtractionKeysContainer",
    "template"
  ]

  connect() {
  }

  disconnect() {
  }

  add(event) {
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime());
    this.dataExtractionKeysContainerTarget.insertAdjacentHTML('beforeend', content);
    const newElement = this.dataExtractionKeysContainerTarget.lastElementChild;
    const firstInput = newElement.querySelector('input');
    if (firstInput) {
      firstInput.focus();
    }
    event.preventDefault();
    return false;
  }
}
