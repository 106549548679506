// Import and register all your controllers from the importmap under controllers/*
import { application } from "./application"

// Eager load all controllers defined in the import map under controllers/**/*_controller
// import { eagerLoadControllersFrom } from "@hotwired/stimulus-loading"
// eagerLoadControllersFrom("controllers", application)

// Lazy load controllers as they appear in the DOM (remember not to preload controllers in import map!)
// import { lazyLoadControllersFrom } from "@hotwired/stimulus-loading"
// lazyLoadControllersFrom("controllers", application)

// import DropdownController from "./dropdown_controller"
// application.register("dropdown", DropdownController)

const controllers = [
  'leave_after',
  'form',
  'bot_stage_instructions',
  'bot_stage_instruction',
  'has_modal',
  'bot_objectives',
  'bot_objective',
  'bot_contact_roles',
  'bot_contact_role',
  'uploadzone',
  'pdf_viewer',
  'removeable',
  'task_types',
  'recaptcha',
  'icon_selector',
  'prompt_versions',
  'attachments_modal',
  'process_table',
  'contacts_table',
  'tasks_table',
  'bot_data_extraction_keys',
  'bot_data_extraction_key',
  
];

controllers.forEach(async (name) => {
  const controller = await import(`./${name}_controller.js`);
  application.register(name.replace(/_/g, '-'), controller.default);
});
