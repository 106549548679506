import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    versions: Array,
    blah: String
  }

  connect() {
  }

  load(event) {
    const selectedId = event.target.value
    if (!selectedId) return

    const version = this.versionsValue.find(v => v.id === selectedId)
    if (!version) return
    console.log(version)

    this.editor = document.querySelector('.CodeMirror')?.CodeMirror

    if (this.editor) {
      this.editor.setValue(version.prompt)
    } else {
      // Fallback to regular textarea if CodeMirror isn't initialized
      document.getElementById('markdown-editor').value = version.prompt
    }
  }
}
